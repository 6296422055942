@import "../core/variables";
/*** Styles ***/
.slick-slider {
  .series {
    background-position: bottom left;
    background-repeat: no-repeat;
    background-position-x: -145px;
    transition: background-position 0.6s linear;
    @media screen and (max-width: 960px) { /** TODO **/
      height: 500px;
    }
    @media screen and (max-width: 767px) { /** TODO **/
      background-position-x: 0;
    }
  }

  .slick-slide {
    opacity: .6;
    transition: visibility 0.3s linear, opacity 0.3s linear;
  }

  .slick-center {
    opacity: 1;
    visibility: visible;
    transition: visibility 0.3s linear, opacity 0.3s linear;
  }

  div.slick-arrow.slick-prev, div.slick-arrow.slick-next {
    display: flex;
    align-items: center;
    & > button {
      margin: 0 auto;
    }
  }

  .overlay {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .hide-not-center {
    visibility: visible;
    opacity: 1;
    transition: visibility 0.7s linear, opacity 0.7s linear;
  }

  // Disable outside center
  .slick-slide:not(.slick-current) {
    .series {
      background-position-x: 0;
      transition: background-position 0.8s linear;
    }

    .hide-not-center {
      visibility: hidden;
      opacity: 0;
      transition: visibility 0.7s linear, opacity 0.7s linear;
    }

    .fade-not-center {
      opacity: 0.3;
      transition: visibility 0.7s linear, opacity 0.7s linear;
    }
  }
  .bottomtitle {
    padding: 0 105px 0 65px;
    transition:visibility 1s linear,opacity 1s linear;
    .left {
      text-align: left;
    }

    .right {
      text-align: right;
    }
  }
  .slick-center .bottomtitle {
    display: none;
    transition:visibility 1s linear,opacity 1s linear;
  }
}

/*** animations ***/
.carousel {
//  min-height: 676px;
  opacity: 0;
  .title {
    h1 {
      font-size: 2.2rem;
    }
  }
}
.carousel.show {
  opacity: 1;
  transition: opacity 1.5s;
}
.progress {
  margin: 100px auto;
}
.carousel-loading {
  width: 100%;
  position: absolute;
  z-index: 1000;
  text-align: center;
  margin: 226px auto;
}

.seriesitem {
  padding-bottom: 10px;
  //height: 676px;
  h1 {
    margin: 170px 0 60px 0;
  }
  button {
    position: absolute;
    bottom: 94px;
  }
}
.models {
  .properties {
    td {
      font-size: 0.8rem;
    }
  }
}

p.large {
  font-size: 16px;
  padding-right: 45px;
}


/*** Mobile Layouts - Media Adjustments ***/

/// XXS devices
@media screen and (max-width: map_get($grid-breakpoints, "sm")) {
  .slick-slider {
    .series {
      //height: 596px;
      background-size: 85%;
    }
    .slick-slide {
      opacity: 1;
    }
    .slick-arrow.slick-prev, .slick-arrow.slick-next {
      height: 100px;
    }
  }
  .models, .seriesitem {
    min-height: 630px;
    h1 {
      font-size: 1.8rem;
      margin: 10px 20px;
    }
    h2 {
      margin: 0 20px;
    }

    p {
      margin: 10px 20px;
    }
  }
}

/// SM devices
@media screen and (min-width: map_get($grid-breakpoints, "sm") + 1) and (max-width: map_get($grid-breakpoints, "md")) {
  .seriesitem {
    button {
      right: 40px;
    }
  }
  .slick-slider {
    .slick-slide {
      opacity: 1;
    }
    .slick-slider .slick-slide {
      //margin: 50px 0;
    }
  }
  .models, .seriesitem {
    min-height: 580px;
    h1 {
      font-size: 2rem;
      margin: 20px;
    }

    p {
      margin: 0 20px;
    }

    .iconbuttons {
      margin: 0 auto;
    }
    &.config {
      min-height: inherit;
      .config {
        padding-bottom: 0;
      }
    }
  }
  .modelitem, .seriesitem {
    padding-bottom: 65px;
  }
}

//MD
@media (min-width: map_get($grid-breakpoints, "md") + 1) {
  .slick-slider {
    .series {
      height: 568px;
      background-size: 75%;
    }
    .colors {
      min-height: 340px;
    }
  }

  .models, .seriesitem {
    min-height: 580px;
    h1 {
      font-size: 3rem;
      margin-top: 105px;
    }
  }
  .models h1 {
    margin-top: 5px;
  }

  .model {
    p.large {
      font-size: 0.9rem;
      padding-right: 15px;
    }
    table {
      font-size: 0.8rem;
    }
  }
  .modelitem {
    h1 {
      font-size: 2.1rem;
      margin-bottom: 0;
    }
  }

  .carousel {
    min-height: 620px;
    .slick-slide > div {
      //padding-top: 60px;
      padding-bottom: 65px;
    }
    .models .slick-slide .colors .slick-slide > div {
      padding-bottom: 0;
    }
    /*.model, .bikecenter {
      padding-top: 84px;
    }*/
  }

  .bikecenter {
    padding-top: 84px;
    min-height: 596px;
  }

  .financeinfo {
    position: absolute;
    bottom: 25px;
  }
}

@media (min-width: map_get($grid-breakpoints, "xl") + 1) {
  .slick-slider {
    .series {
      height: 596px;
      background-size: 75%;
    }
  }
  .models, .seriesitem {
    min-height: 580px;

    h1 {
      font-size: 3rem;
      //margin-top: 105px;
    }
  }
  .carousel {
    min-height: 676px;
  }
}