$icomoon-font-family: "ducati" !default;
$icomoon-font-path: "./fonts" !default;

$duc-gallery-path1: "\e91b";
$duc-gallery-path2: "\e91c";
$duc-gallery-path3: "\e91d";
$duc-fender-right: "\e91e";
$duc-shield: "\e91f";
$duc-umbrella: "\e920";
$duc-payment: "\e921";
$duc-accident: "\e90b";
$duc-advantage: "\e910";
$duc-bikecard: "\e911";
$duc-handshake: "\e912";
$duc-month24de: "\e913";
$duc-startflag: "\e914";
$duc-wrenchcheck: "\e915";
$duc-superbike: "\e91a";
$duc-porsche: "\e909";
$duc-jacket: "\e90a";
$duc-garantie: "\e90c";
$duc-insurance: "\e90d";
$duc-wartung: "\e90e";
$duc-fender-left: "\e90f";
$duc-bilder: "\e907";
$duc-daten: "\e908";
$duc-features: "\e916";
$duc-motorrad: "\e917";
$duc-purse: "\e918";
$duc-videos: "\e919";
$duc-book: "\e900";
$duc-exhaust: "\e901";
$duc-info: "\e902";
$duc-list: "\e903";
$duc-logo: "\e904";
$duc-org: "\e905";
$duc-shirt: "\e906";

