@import "../core/variables";
@import "ducati_colors";
@import "slick";

body {
  background-color: $color-white;
  color: $color-smokyblack;
}

// Color Slider:
.articlenumber {
  color: $color-featuregray;
}

.financing-header {
  background: $color-porsche;
  color: $color-white;
}

.models h2, .modelitem h2 {
  color: $color-darkgray;
  font-size: 1rem;
  margin-top: 0;
  text-transform: uppercase;
  @media screen and (max-width: 959px) {
    margin-left: 20px;
  }
}

/** info icon in header **/
h2 > i.fas.fa-info-circle {
  font-size: 1.5rem;
}

.grey {
  color: $color-darkgray
}

.model-techdata {
  tr:last-child {
    border-bottom-color: #0F0F0F;
  }
}


@media print {
  .noPrint { display: none !important; }
}


/*** Properties Table ***/
/*
.proptable {
  width: calc(100% - 20px);
  position: absolute;
  bottom: 80px;
  tr {
    height: 32px;
    &:last-child {
      border-bottom: 1px solid black;
    }
  }
  td {
    padding: 0;
    border-bottom: 0;
    &:last-child {
      padding-right: 1px;
    }
  }
}*/


table.meine {
  .MuiTableCell-root {
    //background-color: #00a5bb;
    padding: 6px;
  }
  .modelname {
    font-weight: bold;
  }
}


/*** Mobile Layout adaptations ***/
@media screen and (max-width: map_get($grid-breakpoints, "sm")) {
  .config {
    padding-top: 64px;
    h2 {
    font-size: 1.8rem;
    }
  }
}

/**** HACKS ****/
/*** Delivery Select ***/
.MuiIconButton-colorPrimary .MuiFormControlLabel-label {
  color: $color-smokyblack;
}

.MuiCardActions-spacing > :not(:first-child) {
  margin-left: 2px!important;
}