@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?lawe5x');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?lawe5x#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?lawe5x') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?lawe5x') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?lawe5x##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
}

i[class^="duc-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.duc-gallery .path1 {
  &:before {
    content: $duc-gallery-path1;  
    color: rgb(0, 0, 0);
  }
}
.duc-gallery .path2 {
  &:before {
    content: $duc-gallery-path2;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.duc-gallery .path3 {
  &:before {
    content: $duc-gallery-path3;  
    margin-left: -1em;  
    color: rgb(2, 2, 3);
  }
}
.duc-fender-right {
  &:before {
    content: $duc-fender-right; 
  }
}
.duc-shield {
  &:before {
    content: $duc-shield; 
  }
}
.duc-umbrella {
  &:before {
    content: $duc-umbrella; 
  }
}
.duc-payment {
  &:before {
    content: $duc-payment; 
  }
}
.duc-accident {
  &:before {
    content: $duc-accident; 
  }
}
.duc-advantage {
  &:before {
    content: $duc-advantage; 
  }
}
.duc-bikecard {
  &:before {
    content: $duc-bikecard; 
  }
}
.duc-handshake {
  &:before {
    content: $duc-handshake; 
  }
}
.duc-month24de {
  &:before {
    content: $duc-month24de; 
  }
}
.duc-startflag {
  &:before {
    content: $duc-startflag; 
  }
}
.duc-wrenchcheck {
  &:before {
    content: $duc-wrenchcheck; 
  }
}
.duc-superbike {
  &:before {
    content: $duc-superbike; 
  }
}
.duc-porsche {
  &:before {
    content: $duc-porsche; 
  }
}
.duc-jacket {
  &:before {
    content: $duc-jacket; 
  }
}
.duc-garantie {
  &:before {
    content: $duc-garantie; 
  }
}
.duc-insurance {
  &:before {
    content: $duc-insurance; 
  }
}
.duc-wartung {
  &:before {
    content: $duc-wartung; 
  }
}
.duc-fender-left {
  &:before {
    content: $duc-fender-left; 
  }
}
.duc-bilder {
  &:before {
    content: $duc-bilder; 
  }
}
.duc-daten {
  &:before {
    content: $duc-daten; 
  }
}
.duc-features {
  &:before {
    content: $duc-features; 
  }
}
.duc-motorrad {
  &:before {
    content: $duc-motorrad; 
  }
}
.duc-purse {
  &:before {
    content: $duc-purse; 
  }
}
.duc-videos {
  &:before {
    content: $duc-videos; 
  }
}
.duc-book {
  &:before {
    content: $duc-book; 
  }
}
.duc-exhaust {
  &:before {
    content: $duc-exhaust; 
  }
}
.duc-info {
  &:before {
    content: $duc-info; 
  }
}
.duc-list {
  &:before {
    content: $duc-list; 
  }
}
.duc-logo {
  &:before {
    content: $duc-logo; 
  }
}
.duc-org {
  &:before {
    content: $duc-org; 
  }
}
.duc-shirt {
  &:before {
    content: $duc-shirt; 
  }
}

