// Ducati 2017 Colors
$color-red: #CC0000;
$color-offwhite: #FAFAFA;
$color-smokyblack: #0F0F0F;

$color-porsche: #004665;

$color-lightgray: #EBEBEB;
$color-featuregray: #BBBCBC;
$color-darkgray: #666666;

// copied from international site
$color-ducati-web-menu-bg: #f9f9f9;
$color_red_1: #bf0004;
$color_red_2: #c20004;
$color_red_3: #f90005;

$color-invert: $color-offwhite; // light grey
$color-preheader: $color-darkgray; // dark grey
$color-lines: #eee;
$color-white: #fff;
$color-text: #555;
$color-dark-red: #9d0000;  // active red
$color-color1: #222;
$color-color2: $color-dark-red;

// New colors
$gray-color: #999999 !default;
$black-color: #3c4858 !default;

$black: #000000;
$rgb-black: "0,0,0" !default;
$white: #ffffff;
$rgb-white: "255,255,255" !default;